// @flow

import * as React from 'react';
import noop from 'utils/noop';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import { fetchPermissions } from 'modules/permissions';
import { localOpportunitiesInitiatives } from 'modules/stateSponsorship';
import { fetchFeatureFlags } from 'modules/featureFlags';
import { load as loadHighschool } from 'modules/highschool';
import { arePermissionsLoaded } from 'selectors/permissions';
import { localOpportunities } from 'selectors/localOpportunities';
import IfFeatureAllowed from 'components/IfFeatureAllowed';
import Header from 'components/Header';
import HeaderV2 from 'components/HeaderV2';
import HeaderV2Neon from 'components/HeaderV2Neon';
import ScrollHere from 'components/ScrollHere';
import { LegacyHeapAnalytics } from 'components/HeapAnalytics';
import InlineManual from 'components/InlineManual';
import session from 'config/session';
import { getHsid } from 'selectors/highschool';
import {
  needsToChooseStudent,
  getParentId,
  isViewAsStudent,
  isSsoSchoolUser,
  isDelegatedAlumni,
  isGhostingParentUser,
} from 'selectors/auth';
import CollegesRoute from 'routes/Colleges';
import AboutMeRoute from 'routes/AboutMe';
import CareersRoute from 'routes/Careers';
import CoursePlannerNextRoute from 'routes/CoursePlannerNext';
import Careers2Route from 'routes/Careers2';
import JobSearchRoute from 'routes/JobSearch';
import MyPlannerRoute from 'routes/MyPlanner';
import MyPortfolioRoute from 'routes/MyPortfolio';
import HomeRoute from 'routes/Home';
import InboxRoute from 'routes/Inbox';
import ResetPasswordRoute from 'routes/ResetPassword';
import { enableInlineManual, updateInlineManual } from 'utils/inlineManual';
import Privacy from 'routes/Privacy';
import SelectChildRoute from 'routes/SelectChild';
import Documents from 'routes/Documents';
import StudentReadinessReportsRoute from 'routes/StudentReadinessReports';
import AdditionalHelpRoute from 'routes/AdditionalHelp';
import ContactCounselor from 'routes/ContactCounselor';
import DemoUserRoute from 'routes/Demo';
import ViewAsStudentRoute from 'routes/ViewAsStudent';
import ViewAsParentRoute from 'routes/ViewAsParent';
import SchoolUpdates from 'routes/SchoolUpdates';
import LoginRoute from 'routes/Login';
import LogoutRoute from 'routes/Logout';
import ErrorRoute from 'routes/Errors';
import type { FeatureFlags } from 'types/featureFlags';
import type { Location } from 'react-router';
import SchoolCustomPage from 'routes/SchoolCustomPage';
import SiteMap from 'routes/SiteMap';
import ChangesFromMySchool from 'routes/ChangesFromMySchool';
import { areFeatureFlagsLoaded, getFeatureFlags } from 'selectors/featureFlags';

import expandObject from 'utils/expandObject';
/* View As Student */
import ViewAsStudentBanner from 'containers/Accounts/ViewAs/Banner';
/* CLOSED BETA - separate this logic as it is not going last for long */
import ClosedBeta from 'routes/ClosedBeta';
import AdminToast from 'containers/ClosedBeta/toaster/Admin';
// redirect for alumni delegated auth
import { RegisterAlumni } from 'routes/Register';
import SurveysApplicationsRoute from 'routes/SurveysNext';
import ActivityWatcher from 'containers/Accounts/ActivityWatcher';
import MyResumeRoute from 'routes/MyResume';
import { isFeatureFlagAllowed } from '../../utils/permissions';
import { getNewNavigationFlag } from '../../selectors/header';
import TranslateAndReplace from '../TranslateAndReplace';
import { Translator } from '../Translator';
import { Chatterbox } from '../Chatterbox';
import RedirectComponent from './RedirectComponent';
import PowerBuddyWrapper from '../PowerBuddy/index.tsx';
import s from './style.scss';

type Props = {
  showAdminToast: boolean,
  previous: ?string,
  location?: ?Location,
  permissionsReady?: boolean,
  featureFlagsLoaded?: boolean,
  showChooseStudent?: boolean,
  fetchPermissions: Function,
  localOpportunitiesInitiatives: Function,
  fetchFeatureFlags: Function,
  featureFlags?: FeatureFlags,
  loadHighschool: Function,
  hsid: string,
  parentId: ?number,
  isViewingAsStudent: boolean,
  isSchoolUser: boolean,
  isDelegatedAlumniUser: boolean,
  newNavigationEnabled: boolean,
};

const NewFooterV2 = Loadable({
  loader: () => import('components/NewFooterV2' /* webpackChunkName: "footer" */),
  loading: () => null,
});

@withRouter
@connect(
  (state) => ({
    permissionsReady: arePermissionsLoaded(state),
    localOpportunities: localOpportunities(state),
    showChooseStudent: needsToChooseStudent(state),
    featureFlags: getFeatureFlags(state),
    featureFlagsLoaded: areFeatureFlagsLoaded(state),
    isViewingAsStudent: isViewAsStudent(state) || isGhostingParentUser(state),
    showAdminToast: false,
    hsid: getHsid(state),
    parentId: getParentId(state),
    isSchoolUser: isSsoSchoolUser(state),
    isDelegatedAlumniUser: isDelegatedAlumni(state),
    newNavigationEnabled: getNewNavigationFlag(state),
  }),
  {
    fetchPermissions,
    localOpportunitiesInitiatives,
    fetchFeatureFlags,
    loadHighschool,
  }
)
export default class Authorized extends React.Component<Props> {
  static defaultProps = {
    showAdminToast: false,
    permissionsReady: false,
    featureFlagsLoaded: false,
    showChooseStudent: false,
    fetchPermissions: noop,
    localOpportunitiesInitiatives: noop,
    fetchFeatureFlags: noop,
    loadHighschool: noop,
    location: null,
    parentId: null,
    hsid: '',
    isViewingAsStudent: false,
    isSchoolUser: false,
    isDelegatedAlumniUser: false,
    newNavigationEnabled: false,
  };

  componentDidMount() {
    this.props.fetchFeatureFlags();
    this.props.fetchPermissions(this.props.parentId);
    this.props.localOpportunitiesInitiatives();
    this.props.loadHighschool();
    enableInlineManual();
  }

  componentDidUpdate(prevProps: Object) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      updateInlineManual();
    }
  }

  isClosedBeta() {
    const { pathname: currentPath } = this.props.location || {};
    return currentPath.includes('closed-beta');
  }

  isPageBlank() {
    const blankPaths = [
      'reset-password',
      'results/full',
      'report',
      'redirect',
      'about-me/portfolio',
      'update-credentials',
    ];

    const { pathname: currentPath } = this.props.location || {};
    const isBlankPath = blankPaths.some((blankPath) => currentPath.includes(blankPath));
    return isBlankPath || this.isClosedBeta() || session.needsNewPassword();
  }

  arePermissionsAndFeatureFlagsReady() {
    const { featureFlagsLoaded, permissionsReady } = this.props;
    return permissionsReady && featureFlagsLoaded;
  }

  isImpersonatingPath = () => {
    const { pathname: currentPath } = this.props.location || {};
    return (
      currentPath.includes('view-as-student') ||
      currentPath.includes('demo-user') ||
      currentPath.includes('view-as-parent')
    );
  };

  isDemoUser = () => {
    const { pathname: currentPath } = this.props.location || {};
    return currentPath.includes('demo-user');
  };

  renderPrivacyRoutes = () => [
    <Route key="privacy-statement" path="/privacy-statement" component={Privacy} />,
    <Route key="tos" path="/terms-of-service" component={Privacy} />,
  ];

  renderRoutes() {
    const {
      showChooseStudent,
      previous,
      isDelegatedAlumniUser,
      hsid,
      location,
      isSchoolUser,
    } = this.props;

    if (session.needsNewPassword() && !isSchoolUser) {
      return hsid ? (
        <Switch>
          <Route exact path="/:hsid/reset-password" component={ResetPasswordRoute} />
          {this.renderPrivacyRoutes()}
          <Route render={() => <Redirect to={`/${this.props.hsid}/reset-password`} />} />
        </Switch>
      ) : null;
    }

    if (showChooseStudent) {
      return (
        <Switch>
          <Route path="/select-child" component={SelectChildRoute} />
          <Redirect to="/select-child" />
        </Switch>
      );
    }

    if (isDelegatedAlumniUser) {
      return (
        <Switch>
          <Route exact path="/update-credentials" component={RegisterAlumni} />
          <Redirect to="/update-credentials" />
        </Switch>
      );
    }

    if (this.isDemoUser()) {
      return (
        <Switch>
          <Route path="/demo-user/:schoolId" component={DemoUserRoute} />
          <Route path="/demo-user" component={DemoUserRoute} />
          <Redirect to="/demo-user" />
        </Switch>
      );
    }

    const redirectPath = previous && previous !== '/' ? previous : null;
    const noRedirectPages = ['/privacy-statement', '/terms-of-service'];
    if (redirectPath && location && `${location.pathname}${location.search}` !== redirectPath) {
      if (!noRedirectPages.includes(redirectPath)) {
        return <Redirect to={redirectPath} />;
      }
    }

    return (
      <Switch>
        {/* If adding new routes, also update Anonymous */}
        <Route
          path="/main"
          render={() => (
            <HomeRoute
              featureFlags={this.props.featureFlags}
              featureFlagsLoaded={this.props.featureFlagsLoaded}
            />
          )}
        />
        <Route
          path="/colleges"
          render={() => (
            <CollegesRoute
              featureFlags={this.props.featureFlags}
              featureFlagsLoaded={this.props.featureFlagsLoaded}
            />
          )}
        />
        <Route
          path="/careers"
          render={() => (
            <CareersRoute
              featureFlags={this.props.featureFlags}
              featureFlagsLoaded={this.props.featureFlagsLoaded}
            />
          )}
        />
        <Route
          path="/about-me"
          render={() => (
            <AboutMeRoute
              featureFlags={this.props.featureFlags}
              featureFlagsLoaded={this.props.featureFlagsLoaded}
            />
          )}
        />
        <Route path="/inbox" component={InboxRoute} />
        <Route path="/course-planner-next" component={CoursePlannerNextRoute} />
        <Route path="/careers2" component={Careers2Route} />
        <Route path="/job-search" component={JobSearchRoute} />
        <Route path="/closed-beta" component={ClosedBeta} />
        <Route path="/my-planner" component={MyPlannerRoute} />
        {this.props.featureFlagsLoaded &&
        isFeatureFlagAllowed('featureNavianceStudentMfePortfolio', this.props.featureFlags) ? (
          <Route path="/my-portfolio" component={MyPortfolioRoute} />
        ) : (
          <Route path="/my-portfolio" component={() => <div>Portfolio Not Available</div>} />
        )}
        {this.props.featureFlagsLoaded &&
        isFeatureFlagAllowed('featureNavianceStudentMfeResume', this.props.featureFlags) ? (
          <Route path="/my-resume" component={MyResumeRoute} />
        ) : (
          <Route path="/my-resume" component={() => <div>Resume Not Available</div>} />
        )}
        {this.renderPrivacyRoutes()}
        <Route path="/additional-help" component={AdditionalHelpRoute} />
        <Route path="/documents" component={Documents} />
        <Route path="/student-readiness-results" component={StudentReadinessReportsRoute} />
        <Route path="/contact-counselor" component={ContactCounselor} />
        <Route path="/my-school/changes" component={ChangesFromMySchool} />
        <Route path="/school-updates/:id" component={SchoolUpdates} />
        <Route path="/custom-page/:id" component={SchoolCustomPage} />
        <Route path="/view-as-student/:studentId" component={ViewAsStudentRoute} />
        <Route path="/view-as-parent/:parentId/:schoolId" component={ViewAsParentRoute} />
        <Route path="/error" component={ErrorRoute} />
        <Route path="/sitemap" component={SiteMap} />
        {isFeatureFlagAllowed('featureNavianceStudentSurveysMicroApp', this.props.featureFlags) && (
          <Route path="/surveys-next" component={SurveysApplicationsRoute} />
        )}

        {/* redirections described
            https://jira.hobsons.com/browse/NTU-1879
            https://jira.hobsons.com/browse/CORE-29
            https://jira.hobsons.com/browse/CORE-486
          */}
        <Route path="/default" component={RedirectComponent} />
        <Route path="/family-connection/auth/logout" component={LogoutRoute} />
        <Route
          path="/family-connection/colleges/college/view-overlaps"
          component={RedirectComponent}
        />
        <Route path="/family-connection/colleges/college/view/" component={RedirectComponent} />
        <Route path="/family-connection/colleges/application/edit" component={RedirectComponent} />
        <Route path="/family-connection" component={RedirectComponent} />
        <Route path="/success-planner" component={RedirectComponent} />
        <Redirect exact from="/login" to="/main" />
        <Redirect exact from="/bad-hsid" to="/main" />
        <Redirect exact from={`/${hsid}`} to="/main" />
        <Route path="/:hsid" component={LoginRoute} />
        <Redirect to="/main" />
      </Switch>
    );
  }

  render() {
    const { showAdminToast, isViewingAsStudent, isSchoolUser, featureFlags } = this.props;

    const isImpersonatingPath = this.isImpersonatingPath();

    if (!this.arePermissionsAndFeatureFlagsReady()) {
      return null;
    }

    const adminToastPresent = showAdminToast && !this.isClosedBeta();
    const blankPage = this.isPageBlank();

    // check to whether enable people tracking data for Inline Manual or not, based on the LD Toggle
    // if enablePeopleTracking is true, then we need to load trackingData for <InlineManual />
    let trackingData = {};
    const enablePeopleTracking =
      this.props.featureFlagsLoaded &&
      isFeatureFlagAllowed('releaseBlueridgePeopleTracking', this.props.featureFlags);
    if (enablePeopleTracking && session && session.data) {
      let { userJobFunction, id } = session.data;

      if (session.data.loginUser) {
        userJobFunction = session.data.loginUser.userJobFunction;
        id = session.data.loginUser.userId;
      }

      trackingData = {
        uid: id,
        roles: [userJobFunction],
      };
    }

    // If we try a route that is not impersonating we only show login screen
    // or SchoolLookup screen
    if (isSchoolUser && !isImpersonatingPath) {
      const path = expandObject(this, 'props.location.pathname') || '';
      // we clear session data to load login screen
      session.clear();
      return <Redirect to={path} />;
    }

    const homeV2 = featureFlags.featureNavianceStudentHomeV2 === true;
    const homeV2Neon = featureFlags.featureSharedNeonThemeStyles === true;
    const useTranslationClientPackage =
      featureFlags.featureNavianceStudentUseTranslationClientPackage === true;
    const enablePowerbuddy = featureFlags.featureNavianceStudentEnablePowerbuddy === true;
    return (
      <div className={s.main} data-test-id="authorized-div">
        {useTranslationClientPackage ? <Translator /> : <TranslateAndReplace />}
        <div className={s.authorized}>
          <IfFeatureAllowed
            target="releaseBlueridgeHeapAnalytics"
            unless="releaseNavianceStudentAnonHeapAnalytics"
          >
            <LegacyHeapAnalytics />
          </IfFeatureAllowed>
          <IfFeatureAllowed target="releaseBlueridgeInlineManual">
            <InlineManual enablePeopleTracking={enablePeopleTracking} trackingData={trackingData} />
          </IfFeatureAllowed>
          {isViewingAsStudent && <ViewAsStudentBanner />}
          {adminToastPresent && <AdminToast />}
          {!blankPage && !homeV2 && !homeV2Neon && <Header />}
          {!blankPage && homeV2 && !homeV2Neon && <HeaderV2 />}
          {!blankPage && homeV2Neon && <HeaderV2Neon />}
          <ScrollHere location={location} />
          {enablePowerbuddy && <PowerBuddyWrapper />}

          {this.renderRoutes()}
        </div>
        <div>{!blankPage && <NewFooterV2 anonymous={isImpersonatingPath} />}</div>
        <IfFeatureAllowed target="releaseNavianceStudentNewActivityWatcher">
          <ActivityWatcher />
        </IfFeatureAllowed>
        <IfFeatureAllowed target="releaseNavianceStudentChatterbox">
          <Chatterbox />
        </IfFeatureAllowed>
      </div>
    );
  }
}
