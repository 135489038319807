import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { Wage } from 'types/wages';
import stateMap from 'utils/stateMap';
import { formatMoney } from 'utils/wages';
import ReactTooltip from 'react-tooltip';
import geography from './states-10m.json';
import styles from './styles.scss';

interface WageMapProps {
  wages: Wage[];
  className: string;
  style?: React.CSSProperties;
}

export const WageMap = ({ wages, className, style }: WageMapProps) => {
  const [toolTip, setToolTip] = useState('');
  const medianWages = wages.map((w) => ({
    ...w,
    median: w.annualMedian
      ? w.annualMedian
      : w.hourlyMedian
      ? w.hourlyMedian * 40 * 52
      : null,
  }));

  const { min, max } = medianWages.reduce(
    (acc, wage) => {
      const median = wage.median;
      if (!median) {
        return acc;
      }
      acc.min = acc.min > median ? median : acc.min;
      acc.max = acc.max < median ? median : acc.max;
      return acc;
    },
    { min: 3000 * 30000, max: 0 }
  );

  const colorScale = scaleLinear<string>()
    .domain([min, max])
    .range(['#FAF6FE', '#7B46AC']);

  return (
    <div className={className} style={style} data-test-id="powerbuddy-wages-map">
      <ComposableMap projection="geoAlbersUsa" data-tip="">
        <Geographies geography={geography}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const cur = medianWages.find(
                (w) => stateMap[w.stateAbbreviation] === geo.properties.name
              );
              return (
                <Geography
                  key={geo.rsmKey}
                  data-testid={cur ? `state-${cur.stateAbbreviation}` : `no-match`}
                  geography={geo}
                  fill={
                    cur?.median ? colorScale(cur.median) : 'var(--neon-0_12_0-color-gray-400)'
                  }
                  onMouseEnter={() => {
                    setToolTip(
                      `${geo.properties.name} - ${
                        cur?.median ? formatMoney(cur.median) : 'No Data'
                      }`
                    );
                  }}
                  onMouseLeave={() => {
                    setToolTip('');
                  }}
                  className={styles.map}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <ReactTooltip className={styles.toolTip}>
        {toolTip && <span>{toolTip}</span>}
      </ReactTooltip>
      <div className={styles.scale}>
        <div className={styles.gradient} data-testid="scale-gradient" />
        <div className={styles.scaleLabel}>
          <span data-testid="max-salary">{formatMoney(max)}</span>
          <span data-testid="min-salary">{formatMoney(min)}</span>
        </div>
      </div>
    </div>
  );
};
