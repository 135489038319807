import IfUserRole from 'components/IfUserRole';
import React, { useEffect, useState } from 'react';
import { dispatchMfeEvent } from '@ps-refarch-ux/mfe-utils';
import { NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED } from 'constants/pbChatBotMfeEventConstants';
import style from './styles.scss';
type PowerBuddyLaunchPointButtonProps = {
  showMenu?: boolean;
  pbShowSingleButton?: boolean;
  children?: React.ReactNode;
};

const options = ['Career Assistance', 'College Exploration'];

export const PowerBuddyLaunchPointButton: React.FC<PowerBuddyLaunchPointButtonProps> = (
  Props
) => {
  const { showMenu, pbShowSingleButton } = Props;
  const [showOptions, setShowOptions] = useState(false);

  const showDropdown = () => setShowOptions(true);
  const hideDropdown = () => setShowOptions(false);

  useEffect(() => {
    document.addEventListener('click', hideDropdown);
    return () => {
      document.removeEventListener('click', hideDropdown);
    };
  }, []);

  const handleOptionClick = (option) => {
    let eventData = {};

    switch (option) {
      case 'Career Assistance':
        eventData = { data: 'Career' }; // * Fill with relevant data */
        break;
      case 'College Exploration':
        eventData = { data: 'College' }; // * Fill with relevant data */
        break;
      default:
        eventData = {};
        break;
    }

    dispatchMfeEvent(
      'naviance_student',
      NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED,
      eventData
    );
  };

  const handleClick = () => {
    dispatchMfeEvent('naviance_student', NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED, {
      data: 'Career',
    });
  };

  return (
    <IfUserRole target={['student']}>
      {pbShowSingleButton && (
        <div className={style.powerbuddyLaunchButton} onClick={() => handleClick()}>
          <PowerBuddyLaunchPoint />
        </div>
      )}
      {!pbShowSingleButton && (
        <div onClick={hideDropdown} className={style.powerbuddyLaunchButton}>
          <div
            onMouseOver={showDropdown}
            onClick={!showMenu ? () => handleOptionClick('') : undefined}
          >
            <PowerBuddyLaunchPoint />
            {showOptions && showMenu && (
              <div className={style.dropdown}>
                {options.map((option) => (
                  <li
                    key={option}
                    className={style.liStyle}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </li>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </IfUserRole>
  );
};
const PowerBuddyLaunchPoint = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_52210)">
      <rect width="32" height="32" rx="16" fill="#A0E6FC" />
      <circle cx="16" cy="16" r="16" fill="#A0E6FC" />
      <rect x="4.90698" y="5.54669" width="5.33333" height="26.4533" fill="#00B6EF" />
      <circle cx="17.3007" cy="15.9606" r="11.1527" fill="#00B6EF" />
      <ellipse cx="17.3003" cy="15.9606" rx="8.55173" ry="8.55172" fill="#F6F6F6" />
      <ellipse cx="19.4286" cy="14.463" rx="3.42857" ry="4.4532" fill="#080808" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4587 10.404C21.7146 11.0529 22.6055 12.6382 22.6204 14.4962C22.2876 14.8459 21.8624 15.0557 21.3991 15.0557C20.3326 15.0557 19.468 13.9442 19.468 12.573C19.468 11.6407 19.8676 10.8285 20.4587 10.404Z"
        fill="white"
      />
    </g>
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#DFE2E2" />
    <defs>
      <clipPath id="clip0_1_52210">
        <rect width="32" height="32" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
