import React, { useEffect, useState } from 'react';
import ApiClient from '../../../utils/ApiClient';
import api from 'config/api';
import { NeonButton } from '@ps-refarch-ux/neon';
import { PathwayImage } from './PathwayImage';
import styles from './FavoriteCareers.scss';
import type { Career } from 'types/careers';
const client = new ApiClient();

interface CareerProps {
  context: any;
  className?: string;
}

export function CareerCard(props: CareerProps): React.ReactElement {
  const [careerDetails, setCareerDetails] = useState([]);
  const [favoriteCareers, setFavoriteCareers] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const uniqueId = useId();

  useEffect(() => {
    const fetchCareerDetails = async () => {
      try {
        const details = await getCareerDetailsByOnetCode(props.context.occupationCodes);
        const favoriteSet = new Set<string>();
        for (const career of details) {
          if (career.studentCareer) {
            favoriteSet.add(career.onetCode);
          }
        }
        setFavoriteCareers(favoriteSet);
        setCareerDetails(details);
      } catch (error) {
        throw new Error('Failed to fetch career details ' + error);
      }
    };
    fetchCareerDetails();
    return () => {
      setCareerDetails([]);
      setFavoriteCareers(new Set());
    };
  }, [props.context]);

  const toggleFavorite = async (onetCode: string) => {
    setLoading(true);
    try {
      if (favoriteCareers.has(onetCode)) {
        await unFavoriteStudentCareer(onetCode);
        setFavoriteCareers((prev) => {
          const newSet = new Set(prev);
          newSet.delete(onetCode);
          return newSet;
        });
      } else {
        await favoriteStudentCareer(onetCode);
        setFavoriteCareers((prev) => new Set(prev).add(onetCode));
      }
    } catch (error) {
      throw new Error('Failed to toggle favorite career: ' + error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`${props.className} ${styles.pbCareerCard}`}
      data-testid="pb-career-card"
    >
      {careerDetails.map((career) => (
        <div key={career.onetCode}>
          <p className={styles.careerFavoriteCardTitle} data-testid="pb-career-title">
            {career.title}
          </p>
          <PathwayImage categoryName={career.categoryName} pathwayId={career.pathwayId} />
          <div className={styles.careerFavoriteCardButtons}>
            <NeonButton
              id={`pb-career-card-${uniqueId}-visit-website-btn`}
              dataType="borderless"
              dataText="Visit profile"
              data-testid="visit-profile-btn"
              onClick={(): void => {
                const openUrl = api.career2 + `${career.onetCode}`;
                window.open(openUrl, '_blank');
              }}
            />
            <NeonButton
              id={`pb-career-card-${uniqueId}-favorite-btn`}
              dataType="primary"
              dataText={
                favoriteCareers.has(career.onetCode) || loading ? 'Favorited' : 'Favorite'
              }
              data-testid="fav-button"
              dataIsSubmit="true"
              dataIcon={
                favoriteCareers.has(career.onetCode) || loading ? 'heart-solid' : null
              }
              onClick={() => toggleFavorite(career.onetCode)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export async function getCareerDetailsByOnetCode(onetCode: string[]): Promise<Career[]> {
  try {
    const response = await client.get(
      `${api.career}/career-details-with-onet-codes?onetCode=${onetCode.join(',')}`
    );
    return response;
  } catch (error) {
    throw new Error('Error fetching career details:' + error);
  }
}

export const unFavoriteStudentCareer = async (onetCode: string) => {
  try {
    const response = await client.delete(`${api.career}/student-career/${onetCode}`);
    return response;
  } catch (error) {
    throw new Error('Failed to unfavorite the career. Please try again.' + error);
  }
};

export const favoriteStudentCareer = async (onetCode: string) => {
  try {
    const response = await client.post(`${api.career}/student-career`, {
      data: {
        onetCode,
      },
    });
    return response;
  } catch (error) {
    throw new Error('Failed to favorite the career. Please try again.' + error);
  }
};

let counter = 0;

export function useId() {
  const [id] = useState(++counter);
  return id;
}
