import get from 'lodash/get';
import { NeonButton } from '@ps-refarch-ux/neon';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeDisplayAMConnectorPopup,
  changeAmMatchesInConnector,
} from 'modules/colleges/activeMatchConnectionMatching';
import {
  blacklistCollege,
  fetchBlacklistedColleges,
} from 'modules/colleges/lookingForStudentsLikeYou';
import { getAllAMConnectibleColleges } from 'selectors/colleges/activeMatchConnectionMatching';
import {
  getUnfilteredMatchingColleges,
  getBlacklistedColleges,
} from 'selectors/colleges/lookingForStudentsLikeYou';
import { collegeConnectionPermissions } from 'selectors/permissions';
import { useId } from './CollegeCard';
import styles from './CollegeCard.scss';
import type { LegacyMatch, AMMatch } from 'types/colleges';
import { CollegePlanningAction } from 'constants/pbChatBotMfeDsApi';

interface CollegeDetails {
  college_name: string;
  navianceId?: string;
  scid?: number;
}
interface CollegeData {
  actionDetails: CollegeDetails;
  actionName: CollegePlanningAction;
}

interface CollegeCardConnectionsProps {
  college: CollegeData;
  cardClass: string;
  sendPostActionMessage: (
    message: string,
    actionName: string,
    actionDetails: CollegePlanningAction
  ) => void;
}

export function CollegeCardConnections({
  college,
  cardClass,
  sendPostActionMessage,
}: CollegeCardConnectionsProps): React.ReactElement {
  const uniqueId = useId();
  const [isConnectionActionUsed, setConnectionActionUsed] = useState(false);
  const dispatch = useDispatch();
  const connectibleLegacyColleges: LegacyMatch[] = useSelector(
    getUnfilteredMatchingColleges
  );
  const connectibleActiveMatchColleges: AMMatch[] = useSelector(
    getAllAMConnectibleColleges
  );
  const blacklistedColleges: number[] = useSelector(getBlacklistedColleges);
  const mayConnectWithColleges = useSelector(collegeConnectionPermissions);

  const suggestions = [
    `Connect with ${college?.actionDetails?.college_name}`,
    'Not interested',
  ];

  const showConnectionAndNotInterestedButtons = (collegeData: CollegeData) => {
    return (
      mayConnectWithColleges &&
      collegeData.actionDetails.scid &&
      collegeData.actionName === CollegePlanningAction.ADDED_TO_LIST_OFFER_CONNECTION &&
      isConnectibleCollege(
        collegeData.actionDetails,
        connectibleLegacyColleges,
        connectibleActiveMatchColleges
      ) &&
      !blacklistedCollege(college.actionDetails.scid, blacklistedColleges)
    );
  };

  // Separate handlers to ensure immediate state update
  const handleConnectClick = () => {
    setConnectionActionUsed(true);
    handleConnectionOfferClick(dispatch, sendPostActionMessage, college.actionDetails);
  };

  const handleNotInterestedClick = async () => {
    setConnectionActionUsed(true);
    await dispatch(blacklistCollege(college.actionDetails.scid));
    await dispatch(fetchBlacklistedColleges());
    await saveConnectionsHandling(dispatch, sendPostActionMessage, college.actionDetails);
  };

  return (
    showConnectionAndNotInterestedButtons(college) && (
      <div className={styles.collegeConnectionButtons}>
        <NeonButton
          id={`pb-college-card-${uniqueId}-connection-btn`}
          disabled={isConnectionActionUsed}
          dataType="secondary"
          dataText={suggestions[0]}
          dataAriaLabel={suggestions[0]}
          data-testid="connection-btn"
          onClick={handleConnectClick}
        />
        <NeonButton
          id={`pb-college-card-${uniqueId}-not-interested-btn`}
          disabled={isConnectionActionUsed}
          dataType="secondary"
          dataText={suggestions[1]}
          dataAriaLabel={suggestions[1]}
          data-testid="not-interested-btn"
          onClick={handleNotInterestedClick}
        />
      </div>
    )
  );
}

function isConnectibleCollege(
  displayedCollege: CollegeDetails,
  connectibleLegacyColleges: LegacyMatch[],
  connectibleActiveMatchColleges: AMMatch[]
): boolean {
  // These results may not be correct in all cases, depending on
  // how robust the data from PowerBuddy is. This is a best effort
  // attempt to match against the connectible college's data.
  const connectibleLegacyCollege = connectibleLegacyColleges.some(
    (college) =>
      college.hobsonsId === displayedCollege.scid ||
      college.name === displayedCollege.college_name
  );
  const connectibleActiveMatchCollege = connectibleActiveMatchColleges.some(
    (college) =>
      college.scid === displayedCollege.scid.toString() ||
      college.name === displayedCollege.college_name
  );
  return connectibleLegacyCollege || connectibleActiveMatchCollege;
}

const postActionMessage = (sendPostActionMessage, notInterested, college) => {
  sendPostActionMessage(
    'yes i have added the college to my apply list',
    notInterested
      ? CollegePlanningAction.NOT_INTERESTED_TO_CONNECT
      : CollegePlanningAction.CONNECTION_COMPLETE,
    {
      collegeName: college.college_name,
      scid: college?.scid,
      navianceId: college?.navianceId,
      college_name: college.college_name,
    }
  );
};

function blacklistedCollege(
  displayedCollegeScid: number,
  blacklistedCollegeScids: number[]
): boolean {
  return blacklistedCollegeScids.includes(displayedCollegeScid);
}

function handleConnectionOfferClick(
  dispatch: any,
  sendPostActionMessage: any,
  college: CollegeDetails
) {
  const matchInConnector = {
    scid: get(college, 'scid', '').toString(),
    name: get(college, 'college_name', ''),
  };
  dispatch(changeAmMatchesInConnector([matchInConnector]));
  dispatch(changeDisplayAMConnectorPopup(true));
  return dispatch(triggerConnectionsModal).then(
    postActionMessage(sendPostActionMessage, false, college)
  );
}

function saveConnectionsHandling(
  dispatch: any,
  sendPostActionMessage: any,
  college: CollegeDetails
): Promise<void> {
  return dispatch(saveStudentDataInConnections).then(
    postActionMessage(sendPostActionMessage, true, college)
  );
}

export function saveStudentDataInConnections() {
  // TODO: save data in connections
  return Promise.resolve();
}

export function triggerConnectionsModal() {
  // TODO: trigger connections modal
  return Promise.resolve();
}
