import { MfeEvent, addMfeEventListener } from '@ps-refarch-ux/mfe-utils';
import {
  MFE_EVENT_POWERBUDDY_COLLEGE_FAVORITED,
  MFE_EVENT_POWERBUDDY_ERROR,
  MFE_EVENT_POWERBUDDY_OPEN_COLLEGE_PROFILE_REQUESTED,
  MFE_EVENT_COLLEGE_STATUS_REQUESTED,
  MFE_EVENT_USER_MESSAGE_SENT,
  NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED,
} from 'constants/pbChatBotMfeEventConstants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { checkAndRenewToken } from './helpers';
import {
  saveCollegesIamThinking,
  saveCollegesIamThinkingByHobsonsIds,
  fetchAllConsidering,
  fetchAllConsideringInIsolation,
} from 'modules/colleges/considering';
import session from '../../config/session';
import type { CollegeFavoriteList } from 'types/colleges';

interface OpenCollegeProfileRequestedData {
  scid: string;
}

export enum FavoriteStatus {
  FAVORITED = 'favorited',
  NOT_FAVORITED = 'not-favorited',
  UNKNOWN = 'unknown',
  ERROR = 'error',
}

export enum ConnectionStatus {
  OFFER_CONNECTION = 'offer-connection',
  NO_CONNECTION = 'no-connection',
}
interface CollegeStatus {
  favoriteStatus: FavoriteStatus;
}

interface CompletionData {
  status: 'success' | 'error';
}

interface CollegeStatusRequestedEventContext {
  onCompletion: (completionData: CollegeStatus) => void;
  data: {
    scid?: string;
    ceebCode?: string;
    ipedsCode?: string;
    college: {
      name: string;
      websiteUrl?: string;
      imageUrl?: string;
      logoUrl?: string;
    };
  };
}

interface FavoriteCreationEventData {
  onCompletion: (completionData: CompletionData) => void;
  data: AddFavoriteData;
}

export interface AddFavoriteData {
  scid?: string;
  navianceId?: string;
  ceebCode?: string;
  ipedsCode?: string;
  college: {
    name: string;
    websiteUrl?: string;
    imageUrl?: string;
    logoUrl?: string;
  };
}

interface FavoriteCreationEvent extends MfeEvent {
  context: FavoriteCreationEventData;
}

interface CollegeStatusRequestedEvent extends MfeEvent {
  context: CollegeStatusRequestedEventContext;
}

// Any new PowerBuddy MFE event listeners should be added here
// Each should be in a separate useEffect
export const usePowerBuddyListeners = () => {
  const dispatch = useDispatch();

  // Open college profiles in a new tab
  useEffect(() => {
    return addMfeEventListener(
      MFE_EVENT_POWERBUDDY_OPEN_COLLEGE_PROFILE_REQUESTED,
      (event) => {
        const data = event.context as OpenCollegeProfileRequestedData;
        window.open(`/colleges/hubs/${data.scid}`, '_blank');
      }
    );
  }, []);

  useEffect(() => {
    const collegeFavoriteStatusListener = addMfeEventListener(
      MFE_EVENT_COLLEGE_STATUS_REQUESTED,
      async (powerbuddyMfeEvent: CollegeStatusRequestedEvent) => {
        const onCompletion = powerbuddyMfeEvent.context.onCompletion;

        const favoritedCollegesQuery = fetchAllConsidering()(dispatch);

        const favoritedColleges = await favoritedCollegesQuery;

        const isCollegeFavorited = _isCollegeFavorited(
          powerbuddyMfeEvent.context.data,
          favoritedColleges.data
        );

        onCompletion({
          favoriteStatus: isCollegeFavorited
            ? FavoriteStatus.FAVORITED
            : FavoriteStatus.NOT_FAVORITED,
        });
      }
    );

    return () => {
      collegeFavoriteStatusListener();
    };
  }, []);

  // Log PowerBuddy MFE errors to the console
  useEffect(() => {
    return addMfeEventListener(MFE_EVENT_POWERBUDDY_ERROR, (error: MfeEvent) => {
      if (error.context.error) {
        console.log('PowerBuddy MFE Error:', error);
      }
    });
  }, []);

  useEffect(() => {
    const addToListHandler = (powerbuddyMfeEvent: FavoriteCreationEvent) => {
      _handleAddToList(dispatch, powerbuddyMfeEvent);
    };

    const addToListListener = addMfeEventListener(
      MFE_EVENT_POWERBUDDY_COLLEGE_FAVORITED,
      addToListHandler
    );
    return () => {
      addToListListener();
    };
  }, []);

  useEffect(() => {
    const debouncedCheckAndRenewToken = debounce(checkAndRenewToken, 3000);
    return addMfeEventListener(MFE_EVENT_USER_MESSAGE_SENT, () => {
      debouncedCheckAndRenewToken(dispatch);
    });
  }, []);

  useEffect(() => {
    session.registerTokenListener(() => {
      checkAndRenewToken();
    });

    return () => {
      session.unRegisterTokenListener(() => {
        checkAndRenewToken();
      });
    };
  }, []);

  // Fetch favorited colleges every time PowerBuddy is opened, not just on first load
  // This ensures that the favorited status is always up to date when it opens
  useEffect(() => {
    return addMfeEventListener(NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED, () => {
      dispatch(fetchAllConsideringInIsolation());
    });
  });
};

const _handleAddToList = async (dispatch, powerbuddyMfeEvent: FavoriteCreationEvent) => {
  const collegeFavorited =
    powerbuddyMfeEvent.context?.data?.navianceId?.length > 0
      ? saveCollegesIamThinking([powerbuddyMfeEvent.context.data.navianceId])(dispatch)
      : saveCollegesIamThinkingByHobsonsIds([powerbuddyMfeEvent.context.data.scid])(
          dispatch
        );

  collegeFavorited
    .then(() => {
      powerbuddyMfeEvent.context.onCompletion({ status: 'success' });
    })
    .catch(() => {
      powerbuddyMfeEvent.context.onCompletion({ status: 'error' });
    });
};

const _isCollegeFavorited = (
  displayedCollege: AddFavoriteData,
  favoritedColleges: CollegeFavoriteList
): boolean => {
  // These results may not be correct in all cases, depending on
  // how robust the data from PowerBuddy is. This is a best effort
  // attempt to match against the favorited college's data.
  return favoritedColleges.some((favorite) => {
    return (
      // Attempt to match on any of the unique identifiers
      (favorite.college.hobsonsId != null &&
        favorite.college.hobsonsId.toString() === displayedCollege.scid) ||
      (favorite.college?.name != null &&
        favorite.college.name === displayedCollege.college.name)
    );
  });
};
