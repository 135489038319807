import {
  DynamicComponent,
  addMfeEventListener,
  dispatchMfeEvent,
  setTranslations,
} from '@ps-refarch-ux/mfe-utils';
import { pbChatbotMFEUiUrl } from 'config/api';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withAmEventApplications } from 'containers/Colleges/ActiveMatchMicroHoc';
import ActiveMatchConnectDisconnectActions from 'routes/Colleges/Match/ActiveMatchNext/connectDisconnectActions';
import { getCurrentUser } from 'selectors/auth';
import { getHighSchool } from 'selectors/highschool';
import type { State as HighSchool } from 'modules/highschool';
import { usePowerBuddyListeners } from './usePowerBuddyListeners';
import { PbMfeConfigProps } from 'types/pbChatbotMfe';
import {
  constructUserContext,
  fetchSessionId,
  fetchUserCollegeInfo,
  fetchUserOneDsInfo,
  getOneDsInitializationData,
} from 'components/PowerBuddy/helpers';
import session from '../../config/session';
import { dsApiUrl, oneDsApiUrl } from 'config/api';
import {
  pbNavinceProductCode,
  pbShouldRenderInDrawer,
  pbShouldSkipAuthToken,
  translations,
} from 'constants/pbChatBotMfe';
import {
  collegeProfilesPermissions,
  collegeFavoritingPermissions,
} from 'selectors/permissions';
import {
  HOST_EVENT_POWERBUDDY_INFORMATION_UPDATED,
  HOST_EVENT_POWERBUDDY_OPEN_REQUESTED,
  MFE_EVENT_CAREER_PATHWAY_GOALS,
  MFE_EVENT_POWERBUDDY_INITIALIZED,
  MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED,
  MFE_HOST_NAME,
  NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED,
} from 'constants/pbChatBotMfeEventConstants';
import s from './styles.scss';
import cx from 'classnames';
import { CustomPowerBuddyComponents } from './CustomPowerBuddyComponents';
import { getFeatureFlags } from 'selectors/featureFlags';

export const PowerBuddyWrapper = ({ eventApplications }) => {
  // General handling of PowerBuddy events should be done in this hook
  usePowerBuddyListeners();

  const featureFlags = useSelector(getFeatureFlags) as Record<string, boolean>;
  const useExtensibleCollegeCard =
    featureFlags.releaseNavianceStudentPowerBuddyEnableExtensibilityCollegeCard;
  const powerBuddyDropdownOptions =
    featureFlags.releaseNavianceStudentPowerBuddyDropdownOptions;
  const powerBuddyFavoriteCollege =
    featureFlags.releaseNavianceStudentPowerBuddyFavoriteCollege;
  const powerBuddyConnectWithCollege =
    featureFlags.releaseNavianceStudentPowerBuddyEnableCollegeConnections;

  const isPowerBuddyFeedbackTookEnabled =
    featureFlags.featureNavianceStudentPowerBuddyShowFeedback;
  const [initializationData, setInitializationData] = useState(null);
  const contextTypeRef = useRef('');
  const initDataFetchedRef = useRef(false);

  const currentUser = useSelector(getCurrentUser);
  const highSchool: HighSchool = useSelector(getHighSchool);

  const dispatchSessionToken = (data) => {
    dispatchMfeEvent(
      'naviance_student',
      MFE_EVENT_POWERBUDDY_SESSION_TOKEN_ISSUED,
      data.dataScienceSessionId
    );
  };

  const collegeProfiles = useSelector(collegeProfilesPermissions);

  const addOrEditProspectiveCollegesList = useSelector(collegeFavoritingPermissions);

  const customPermissions: string[] = [];

  if (collegeProfiles) {
    if (!customPermissions.includes('college_profiles')) {
      customPermissions.push('college_profiles');
    }
  }
  if (addOrEditProspectiveCollegesList) {
    if (!customPermissions.includes('add_or_edit_prospective_colleges_list')) {
      customPermissions.push('add_or_edit_prospective_colleges_list');
    }
  }

  useEffect(() => {
    return addMfeEventListener(
      NAV_STUDENT_EVENT_POWERBUDDY_OPEN_REQUESTED,
      (mfeEvents: any) => {
        if (!currentUser || !highSchool) return;

        // If it's already initialized, just open the PowerBuddy MFE
        if (
          initDataFetchedRef.current &&
          mfeEvents.context.data === contextTypeRef.current
        ) {
          dispatchMfeEvent('naviance_student', HOST_EVENT_POWERBUDDY_OPEN_REQUESTED, {});
          return;
        }

        if (contextTypeRef.current !== mfeEvents.context.data) {
          // Initialize translations for PowerBuddy
          setTranslations(translations);

          let initializationDataPromise;

          const useOneDSApi = !!powerBuddyDropdownOptions;

          if (mfeEvents.context.data === 'College') {
            initializationDataPromise = getInitializationData(
              currentUser,
              highSchool,
              customPermissions,
              useOneDSApi
            );
          } else if (mfeEvents.context.data === 'Career') {
            initializationDataPromise = getOneDsInitializationData(
              currentUser,
              highSchool,
              customPermissions,
              isPowerBuddyFeedbackTookEnabled
            );
          }
          initializationDataPromise
            ?.then((data: any) => {
              setInitializationData(data);
              dispatchSessionToken(data);
            })
            .catch((error) => {
              // log error
            });

          contextTypeRef.current = mfeEvents.context.data;
        }

        initDataFetchedRef.current = true;
      }
    );
  }, [currentUser, highSchool]);

  // When first initialized, request the PowerBuddy MFE to open
  useEffect(() => {
    return addMfeEventListener(MFE_EVENT_POWERBUDDY_INITIALIZED, (mfeEvent: any) => {
      if (mfeEvent.context.initializedCounter === 0)
        dispatchMfeEvent('naviance_student', HOST_EVENT_POWERBUDDY_OPEN_REQUESTED, {});
    });
  }, []);

  const getOneDsContext = async (currentUserContext: any) => {
    const userCareerContext = await fetchUserOneDsInfo(currentUserContext);
    return userCareerContext;
  };

  useEffect(() => {
    return addMfeEventListener(MFE_EVENT_CAREER_PATHWAY_GOALS, async (event) => {
      if (event?.context?.context) {
        const pathwayContext = { ...event.context.context };
        const careerContext = await getOneDsContext(currentUser);

        careerContext.primaryGoal = pathwayContext.primaryGoal;
        careerContext.secondaryGoal = pathwayContext.secondaryGoal;

        dispatchMfeEvent(MFE_HOST_NAME, HOST_EVENT_POWERBUDDY_INFORMATION_UPDATED, {
          context: { ...careerContext },
        });
      }
    });
  }, [currentUser]);

  if (!initializationData) {
    return null;
  }

  return (
    // default in PowerBuddy is to show the "Add to list" button, therefore need to disable it if the Beta V2 feature flag is OFF
    <div
      id={s.boxSizing}
      className={cx({
        [s.disableAddToListButton]: !powerBuddyFavoriteCollege,
        [s.disableBuiltInCollegeCard]: useExtensibleCollegeCard,
      })}
    >
      <DynamicComponent
        remote="ps_mfe_ph_ai_chatbot"
        url={pbChatbotMFEUiUrl}
        module="./PhAiChatBot"
        locale="en-US"
        componentProps={initializationData}
      />
      <CustomPowerBuddyComponents />
      {powerBuddyConnectWithCollege && eventApplications?.POWERBUDDY && (
        <ActiveMatchConnectDisconnectActions
          hostApplication={eventApplications.POWERBUDDY}
        />
      )}
    </div>
  );
};

async function getInitializationData(
  currentUser: any,
  highSchool: HighSchool,
  customPermissions: string[],
  useOneDSApi: boolean
): Promise<{ config: PbMfeConfigProps; dataScienceSessionId: string }> {
  // Fetch data from the backend in parallel
  const [dsSessionId, userCareerContext] = await Promise.all([
    fetchSessionId(),
    fetchUserCollegeInfo(),
  ]);

  const { data } = session;
  const userProfile = {
    ...data,
    currentUser,
    highSchool,
    customPermissions,
  };
  // Fetch the user's context information
  const userInfoContext = await constructUserContext(userProfile);
  // Construct the student session ID
  const studentSessionId = `${userInfoContext.districtId}-${userInfoContext.id}-${dsSessionId}`;

  // Construct the configuration context for the PowerBuddy MFE
  const configContext: PbMfeConfigProps = {
    mfeApiPrefix: useOneDSApi ? oneDsApiUrl : dsApiUrl,
    productCode: pbNavinceProductCode,
    shouldSkipAuthToken: pbShouldSkipAuthToken,
    shouldRenderInDrawer: pbShouldRenderInDrawer,
    sessionId: studentSessionId,
    user: userInfoContext,
    context: userCareerContext,
    useOnePowerBuddy: useOneDSApi,
  };

  // Return the configuration context
  return { config: configContext, dataScienceSessionId: dsSessionId };
}

export default withAmEventApplications(PowerBuddyWrapper);
