import { addMfeEventListener, dispatchMfeEvent } from '@ps-refarch-ux/mfe-utils';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { RoadtripNationVideos } from 'containers/PowerBuddyCareerCard/RoadTripNationVideos';
import { useSelector } from 'react-redux';
import { CollegeCard } from './CollegeCard';
import React from 'react';
import { getFeatureFlags } from 'selectors/featureFlags';
import { MFE_EVENT_POWERBUDDY_INITIALIZED } from 'constants/pbChatBotMfeEventConstants';
import { CollegeCardConnections } from './CollegeCardConnections';
import { WageCard } from './WageCard';
import { CareerCard } from 'containers/PowerBuddyCareerCard/FavoriteCareers/FavoriteCareersPb';

export const CustomPowerBuddyComponents = () => {
  const [customPortaledElements, setCustomPortaledElements] = useState<
    React.ReactPortal[]
  >([]);

  const featureFlags = useSelector(getFeatureFlags) as Record<string, boolean>;

  useEffect(() => {
    return addMfeEventListener(MFE_EVENT_POWERBUDDY_INITIALIZED, () => {
      // The key is the name of the context data type that is returned by the DS API
      // That is how PowerBuddy knows which component to render.
      // To add another custom component, add another key-value pair here.
      const customComponents: Record<string, (...args: any[]) => any> = {};
      if (featureFlags.releaseNavianceStudentPowerBuddyEnableExtensibilityCollegeCard) {
        customComponents.college_planning = ({
          node,
          contextData,
          cssClasses,
          operations,
        }) => {
          if (
            'scid' in contextData &&
            'name' in contextData &&
            contextData.name.trim().length > 0
          ) {
            setCustomPortaledElements((prev) => [
              ...prev,
              createPortal(
                <CollegeCard
                  college={contextData}
                  sendPostActionMessage={operations.sendPostActionMessage}
                  cardClass={cssClasses.card}
                />,
                node
              ),
            ]);
          }
        };
      }
      if (featureFlags.releaseNavianceStudentPowerBuddyEnableCollegeConnections) {
        // Add college connections
        customComponents.college_action = ({
          node,
          contextData,
          cssClasses,
          operations,
        }) => {
          setCustomPortaledElements((prev) => [
            ...prev,
            createPortal(
              <CollegeCardConnections
                college={contextData}
                sendPostActionMessage={operations.sendPostActionMessage}
                cardClass={cssClasses.card}
              />,
              node
            ),
          ]);
        };
      }
      if (featureFlags.featureNavianceStudentPowerbuddyRoadTripNationVideos) {
        customComponents.roadtrip_nation = ({ node, contextData, cssClasses }) => {
          setCustomPortaledElements((prev) => [
            ...prev,
            createPortal(
              <RoadtripNationVideos
                roadTripNationData={contextData}
                className={cssClasses.card}
              />,
              node
            ),
          ]);
        };
      }
      if (featureFlags.featureNavianceStudentPowerbuddyFavouriteCareer) {
        customComponents.career_planning = ({ node, contextData, cssClasses }) => {
          setCustomPortaledElements((prev) => [
            ...prev,
            createPortal(
              <CareerCard context={contextData} className={cssClasses.card} />,
              node
            ),
          ]);
        };
      }
      if (featureFlags.featureNavianceStudentPowerbuddyWageMap) {
        customComponents.wages = ({ node, contextData, cssClasses }) => {
          setCustomPortaledElements((prev) => [
            ...prev,
            createPortal(
              <WageCard context={contextData} className={cssClasses.card} />,
              node
            ),
          ]);
        };
      }

      dispatchMfeEvent(
        'host_app_name',
        'host.load-custom-pb-components',
        {
          customComponents,
        },
        true
      );
    });
  }, []);
  return <>{customPortaledElements}</>;
};
