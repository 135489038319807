import { NeonButton } from '@ps-refarch-ux/neon';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getConsideringAllEntities } from 'selectors/colleges';
import {
  saveCollegesIamThinking,
  saveCollegesIamThinkingByHobsonsIds,
} from 'modules/colleges/considering';
import { getAllAMConnectibleColleges } from 'selectors/colleges/activeMatchConnectionMatching';
import { getUnfilteredMatchingColleges } from 'selectors/colleges/lookingForStudentsLikeYou';
import { fetchUnfilteredMatchingColleges } from 'modules/colleges/lookingForStudentsLikeYou';
import { fetchCollegeUuid } from 'modules/colleges/hubs';
import {
  collegeProfilesPermissions,
  collegeFavoritingPermissions,
  collegeConnectionPermissions,
} from 'selectors/permissions';
import { getFeatureFlags } from 'selectors/featureFlags';
import type { LegacyMatch, AMMatch } from 'types/colleges';
import {
  CollegePlanningAction,
  POST_FAV_ACTION_MESSAGE,
} from 'constants/pbChatBotMfeDsApi';
import { getBlacklistedColleges } from 'selectors/colleges/lookingForStudentsLikeYou';

import defaultCollegeImage from './defaultCollegeImage.svg';
import styles from './CollegeCard.scss';

export interface CollegeData {
  name: string;
  url: string;
  imageUrl?: string;
  scid?: number;
  navianceId?: string;
  ceebCode?: string;
  ipedsCode?: string;
}

export interface CollegeCardProps {
  college: CollegeData;
  cardClass: string;
  sendPostActionMessage: (
    message: string,
    actionName: string,
    actionDetails: CollegePlanningActionDetails
  ) => void;
}

interface CollegePlanningActionDetails {
  college_name: string;
  scid?: number;
  navianceId?: string;
}

// Validates that the URL is either an HTTP/HTTPS URL or a data URL with an image MIME type
// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs
const validImageUrlRegex = /^https?:\/\/|^data:image\/\w+;base64,/;

export function CollegeCard({
  college,
  cardClass,
  sendPostActionMessage,
}: CollegeCardProps): React.ReactElement {
  const uniqueId = useId();
  const isFavorited = useIsFavorited(college.scid);

  const [collegeUuid, setCollegeUuid] = useState<string>(null);

  const mayViewCollegeProfiles = useSelector(collegeProfilesPermissions);
  const showCollegeCard = Boolean(collegeUuid);

  const featureFlags = useSelector(getFeatureFlags) as Record<string, boolean>;
  const pbFavoritingEnabled =
    featureFlags.releaseNavianceStudentPowerBuddyFavoriteCollege;
  const pbConnectingEnabled =
    featureFlags.releaseNavianceStudentPowerBuddyEnableCollegeConnections;

  const mayAddColleges = useSelector(collegeFavoritingPermissions);
  const mayConnectWithColleges = useSelector(collegeConnectionPermissions);

  const blacklistedColleges: number[] = useSelector(getBlacklistedColleges);

  const dispatch = useDispatch();
  const [isInProgress, setIsInProgress] = useState(false);

  const isValidImageUrl = validImageUrlRegex.test(college.imageUrl);

  useEffect(() => {
    dispatch(fetchUnfilteredMatchingColleges());

    dispatch(fetchCollegeUuid(college.scid)).then((collegeData) => {
      if (collegeData.uuid) {
        setCollegeUuid(collegeData.uuid as string);
      }
    });
  }, []);

  const connectibleLegacyColleges: LegacyMatch[] = useSelector(
    getUnfilteredMatchingColleges
  );
  const connectibleActiveMatchColleges: AMMatch[] = useSelector(
    getAllAMConnectibleColleges
  );

  // Auxiliary function to determine if a college is connectible
  const mayStudentConnectWithThisCollege = () => {
    return (
      pbConnectingEnabled &&
      mayConnectWithColleges &&
      isConnectibleCollege(
        college,
        connectibleLegacyColleges,
        connectibleActiveMatchColleges
      ) &&
      !blacklistedCollege(college.scid, blacklistedColleges)
    );
  };

  const AddToListCallback = async () => {
    const messageType = mayStudentConnectWithThisCollege()
      ? CollegePlanningAction.ADDED_TO_LIST_OFFER_CONNECTION
      : CollegePlanningAction.ADDED_TO_LIST_NO_CONNECTION;

    // Simplified payload assignment
    const payload = {
      college_name: college.name,
      scid: college?.scid,
      navianceId: college?.navianceId,
    };

    // Post the action message
    sendPostActionMessage(POST_FAV_ACTION_MESSAGE, messageType, payload);
  };

  const handleFavoriteClickAndConnectionOffer = () => {
    setIsInProgress(true);
    // if navianceId is present, use it to save the college or use scid to save the college
    const saveCollegeAction =
      college.navianceId?.length > 0
        ? saveCollegesIamThinking([college.navianceId])
        : saveCollegesIamThinkingByHobsonsIds([college.scid]);

    dispatch(saveCollegeAction)
      .then(AddToListCallback)
      .catch((error) => {
        throw new Error('This college could not be added to your list. Try again.');
      })
      .finally(() => setIsInProgress(false));
  };

  return (
    <div>
      {showCollegeCard && (
        <div className={`${cardClass} ${styles.collegeCard}`} data-testid="college-card">
          <p className={styles.collegeCardTitle}>{college.name}</p>
          <img
            className={styles.collegeCardImage}
            src={isValidImageUrl ? college.imageUrl : defaultCollegeImage}
            // Because the image is purely decorative and we don't know the content in advance,
            // we should use an empty alt attribute.
            // See: https://www.w3.org/WAI/tutorials/images/decorative/
            alt=""
          />
          <div className={styles.collegeCardButtons}>
            {mayViewCollegeProfiles && (
              <NeonButton
                id={`pb-college-card-${uniqueId}-visit-website-btn`}
                dataType="borderless"
                dataText="Visit profile"
                dataIcon="open-in-new"
                data-testid="visit-website-btn"
                onClick={(): void => {
                  window.open(`/colleges/profile/${collegeUuid}/overview`, '_blank');
                }}
              />
            )}
            {pbFavoritingEnabled && mayAddColleges && (
              <NeonButton
                id={`pb-college-card-${uniqueId}-add-to-list-btn`}
                dataType="primary"
                // Ideally, we would use dataIsLoading for when in progress
                // but we are stuck on an old version of Neon for the moment
                disabled={isFavorited || isInProgress}
                dataText="Add to list"
                dataAriaLabel="add college to favorite colleges list"
                dataIcon="add"
                data-testid="favorite-college-btn"
                onClick={handleFavoriteClickAndConnectionOffer}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

let idCounter = 0;

// This is a simple backport of the useId hook from React 18
// It is not suitable for all the same use cases as the React 18 version
// but meets the needs of this component
export function useId() {
  const [id] = useState(++idCounter);
  return id;
}

function useIsFavorited(scid: number): boolean {
  const allFavorited = useSelector(getConsideringAllEntities);
  return Object.values(allFavorited).some((college) => college.hobsonsId === scid);
}

function isConnectibleCollege(
  displayedCollege: CollegeData,
  connectibleLegacyColleges: LegacyMatch[],
  connectibleActiveMatchColleges: AMMatch[]
): boolean {
  // These results may not be correct in all cases, depending on
  // how robust the data from PowerBuddy is. This is a best effort
  // attempt to match against the connectible college's data.
  const connectibleLegacyCollege = connectibleLegacyColleges.some(
    (college) =>
      college.hobsonsId === displayedCollege.scid ||
      college.name === displayedCollege.name
  );
  const connectibleActiveMatchCollege = connectibleActiveMatchColleges.some(
    (college) =>
      college.scid === displayedCollege.scid.toString() ||
      college.name === displayedCollege.name
  );
  return connectibleLegacyCollege || connectibleActiveMatchCollege;
}

function blacklistedCollege(
  displayedCollegeScid: number,
  blacklistedCollegeScids: number[]
): boolean {
  return blacklistedCollegeScids.includes(displayedCollegeScid);
}
