// @flow
import { College as CollegeType } from 'types/colleges';
import constantsGenerator from 'utils/constantsGenerator';
import api from 'config/api';
import { notify } from 'modules/notification';
import { getConsideringEntities } from 'selectors/colleges';
import { isLinkExternal } from 'utils/link';
import expandObject from 'utils/expandObject';
import { paginatedSearch } from 'utils/pagination';
import { dispatchMfeEvent } from '@ps-refarch-ux/mfe-utils';
import {
  NAV_STUDENT_EVENT_COLLEGE_FAVORITED,
  NAV_STUDENT_EVENT_COLLEGE_UNFAVORITED,
} from 'constants/pbChatBotMfeEventConstants';
import { CLEAR_COLLEGES } from './constants';

const generateConstants = constantsGenerator('fc/considering');
const SELECT_COLLEGE = 'fc/considering/SELECT_COLLEGE';
const UNSELECT_COLLEGE = 'fc/considering/UNSELECT_COLLEGE';
const SELECT_INTEREST = 'fc/considering/SELECT_INTEREST';
const CLEAN_PICKED_COLLEGES = 'fc/considering/CLEAN_PICKED_COLLEGES';
const ADD_TO_APPLICATION_LIST = 'fc/considering/ADD_TO_APPLICATION_LIST';
const REMOVE_FROM_APPLICATION_LIST = 'fc/considering/REMOVE_FROM_APPLICATION_LIST';
const UPDATE_APPLICATION_LIST = 'fc/considering/UPDATE_APPLICATION_LIST';

export const CLEAR_DEADLINE_TYPES = 'fc/considering/CLEAR_COLLEGE_DEADLINE_TYPES';

const [
  GET_COLLEGES_IAM_THINKING,
  GET_COLLEGES_IAM_THINKING_SUCCESS,
  GET_COLLEGES_IAM_THINKING_FAIL,
]: string[] = generateConstants('GET_COLLEGES_IAM_THINKING');

const [
  GET_COLLEGE_DEADLINE_TYPES,
  GET_COLLEGE_DEADLINE_TYPES_SUCCESS,
  GET_COLLEGE_DEADLINE_TYPES_FAIL,
]: string[] = generateConstants('GET_COLLEGE_DEADLINE_TYPES');

const [
  GET_COLLEGES_IAM_THINKING_IN_ISOLATION,
  GET_COLLEGES_IAM_THINKING_IN_ISOLATION_SUCCESS,
  GET_COLLEGES_IAM_THINKING_IN_ISOLATION_FAIL,
]: string[] = generateConstants('GET_COLLEGES_IAM_THINKING_IN_ISOLATION');

export const [
  SAVE_COLLEGES_IAM_THINKING,
  SAVE_COLLEGES_IAM_THINKING_SUCCESS,
  SAVE_COLLEGES_IAM_THINKING_FAIL,
]: string[] = generateConstants('SAVE_COLLEGES_IAM_THINKING');

export const [
  SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING,
  SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_SUCCESS,
  SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_FAIL,
]: string[] = generateConstants('SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING');

const REMOVE_COLLEGE_FROM_PICKED = 'REMOVE_COLLEGE_FROM_PICKED';
export const [
  DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING,
  DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_SUCCESS,
  DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_FAIL,
]: string[] = generateConstants('DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING');

export const CLEAR_FAVORITE_LIST = 'CLEAR_FAVORITE_LIST';
export const [
  DELETE_COLLEGES_IAM_THINKING,
  DELETE_COLLEGES_IAM_THINKING_SUCCESS,
  DELETE_COLLEGES_IAM_THINKING_FAIL,
]: string[] = generateConstants('DELETE_COLLEGES_IAM_THINKING');

const [
  UPDATE_INTERESTS,
  UPDATE_INTERESTS_SUCCESS,
  UPDATE_INTERESTS_FAIL,
]: string[] = generateConstants('UPDATE_INTERESTS');

const [NEXT_PAGE, NEXT_PAGE_SUCCESS, NEXT_PAGE_FAILURE]: string[] = generateConstants(
  'NEXT_PAGE_CONSIDERING'
);

export const [
  SET_PICKED_COLLEGES,
  SET_PICKED_COLLEGES_SUCCESS,
  SET_PICKED_COLLEGES_FAIL,
]: string[] = generateConstants('SET_PICKED_COLLEGES');

export const [GET_SINGLE, GET_SINGLE_SUCCESS, GET_SINGLE_FAIL]: string[] = generateConstants(
  'GET_SINGLE'
);

const CHANGE_IS_LOADING_LEGACY_MATCHES = 'fc/considering/CHANGE_IS_LOADING_LEGACY_MATCHES';

// remember last page we loaded. Set it to -1 so it's immediately invalidated
let lastPage: number = -1;

type State = {
  pickedCollegeIds: Array<string>,
  collegeIdSelections: string[],
  candidateCollegeIds: Array<string>,
  results: Array<Object>,
  entities: Object,
  allResults: Object[],
  allEntities: Object,
  loadingAll: boolean,
  application: Object,
  loading: boolean,
  totalItems: number,
  page: number,
  wereLegacyMatchesLoaded: boolean,
  collegeDeadlineTypes: Array<Object>,
};

const initialState: State = {
  pickedCollegeIds: [],
  collegeIdSelections: [],
  candidateCollegeIds: [],
  results: [],
  entities: {},
  allResults: [],
  allEntities: {},
  loadingAll: false,
  application: {
    results: [],
    entities: {},
  },
  loading: false,
  totalItems: 0,
  page: -1,
  wereLegacyMatchesLoaded: false,
  collegeDeadlineTypes: [],
};

const normalizeItems = (res) =>
  res.data.reduce((items, item) => {
    if (item.id) {
      items[item.college.id] = {
        ...item,
        ...item.college,
        url: isLinkExternal(item.college.url)
          ? item.college.url
          : `${document.location.protocol}//${item.college.url}`,
        prospectiveApplicationId: item.id,
        college: undefined,
      };
    }
    return items;
  }, {});

const filterItems = (ids, entities) =>
  ids.reduce((items, idd) => {
    const college = entities[idd];
    if (college) {
      const { name, id, interestLevel, edocsCollege, continuous, deadlines } = college;
      items[id] = {
        name,
        id,
        interestLevel,
        edocsCollege,
        type: '',
        studentSubmitted: false,
        requestTranscript: false,
        includeSAT: false,
        includeACT: false,
        midYearRequired: false,
        finalRequired: false,
        applyViaCommonapp: '',
        continuous,
        deadlines,
      };
    }
    return items;
  }, {});

const collectIds = (res) => res.data.map((item) => item.college.id);

const saveCollegesIamThinkingInternal = (
  colleges: Array<number>,
  idKey?: string = 'collegeIds'
) => (dispatch: Function) => {
  if (!Array.isArray(colleges) || colleges.length === 0) {
    // $FlowFixMe
    return Promise.reject('No colleges selected');
  }

  return dispatch({
    types: [
      SAVE_COLLEGES_IAM_THINKING,
      SAVE_COLLEGES_IAM_THINKING_SUCCESS,
      SAVE_COLLEGES_IAM_THINKING_FAIL,
    ],
    promise: (client: Object) =>
      client
        .post(`${api.colleges}/colleges-im-thinking-about`, {
          data: {
            [idKey]: colleges,
          },
        })
        .then((data) => {
          // Debugging lines
          console.debug('POST colleges-im-thinking-about response data: ', data);
          // Check if dispatchMfeEvent is defined
          if (typeof dispatchMfeEvent !== 'function') {
            console.debug('dispatchMfeEvent is not defined correctly.');
          } else {
            data?.forEach((item) => {
              // Add a null/undefined check for item and item.college
              if (item && item.college) {
                dispatchMfeEvent(
                  'naviance_student',
                  NAV_STUDENT_EVENT_COLLEGE_FAVORITED,
                  item.college
                );
              } else {
                console.debug('Item or item.college is undefined:', item);
              }
            });
          }

          // Trigger refetch of the full list of CITA colleges so that the list is up to date
          dispatch(fetchAllConsideringInIsolation());
        })
        .catch((error) => {
          console.error('POST colleges-im-thinking-about call failed: ', error);
        }),
  });
};

export const saveCollegesIamThinkingWithoutClearing = (
  colleges: Array<string>,
  idKey?: string = 'collegeIds'
) => (dispatch: Function) => {
  if (!Array.isArray(colleges) || colleges.length === 0) {
    // $FlowFixMe
    return Promise.reject('No colleges selected');
  }

  return dispatch({
    types: [
      SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING,
      SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_SUCCESS,
      SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_FAIL,
    ],
    promise: (client: Object) =>
      client.post(`${api.colleges}/colleges-im-thinking-about`, {
        data: {
          [idKey]: colleges,
        },
      }),
  })
    .then((data) => {
      // Debugging lines
      console.debug('POST colleges-im-thinking-about response data: ', data);
      // Check if dispatchMfeEvent is defined
      if (typeof dispatchMfeEvent !== 'function') {
        console.debug('dispatchMfeEvent is not defined correctly.');
      } else {
        data?.forEach((item) => {
          // Add a null/undefined check for item and item.college
          if (item && item.college) {
            dispatchMfeEvent('naviance_student', NAV_STUDENT_EVENT_COLLEGE_FAVORITED, item.college);
          } else {
            console.debug('Item or item.college is undefined:', item);
          }
        });
      }
      dispatch({
        type: REMOVE_COLLEGE_FROM_PICKED,
        collegeId: colleges[0],
      });

      // Trigger refetch of the full list of CITA colleges so that the list is up to date
      dispatch(fetchAllConsideringInIsolation());
    })
    .catch((error) => {
      console.error('POST colleges-im-thinking-about call failed: ', error);
    });
};

export function deleteCollegesIamThinkingWithoutClearing(
  colleges: Array<number>,
  idKey?: string = 'prospectiveApplicationIds'
) {
  return (dispatch: Function) =>
    dispatch({
      types: [
        DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING,
        DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_SUCCESS,
        DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_FAIL,
      ],
      promise: (client: Object) =>
        client
          .delete(`${api.colleges}/colleges-im-thinking-about`, {
            data: {
              [idKey]: colleges,
            },
          })
          .then((data) => {
            // Debugging lines
            console.debug('POST colleges-im-thinking-about response data: ', data);
            // Check if dispatchMfeEvent is defined
            if (typeof dispatchMfeEvent !== 'function') {
              console.debug('dispatchMfeEvent is not defined correctly.');
            } else {
              data.forEach((item) => {
                // Add a null/undefined check for item and item.college
                if (item && item.college) {
                  dispatchMfeEvent(
                    'naviance_student',
                    NAV_STUDENT_EVENT_COLLEGE_FAVORITED,
                    item.college
                  );
                } else {
                  console.debug('Item or item.college is undefined:', item);
                }
              });
            }

            // Trigger refetch of the full list of CITA colleges so that the list is up to date
            dispatch(fetchAllConsideringInIsolation());
          })
          .catch((error) => {
            console.error('POST colleges-im-thinking-about call failed: ', error);
          }),
    });
}

export function deleteCollegesIamThinkingInternal(
  colleges: Array<number>,
  idKey?: string = 'prospectiveApplicationIds'
) {
  return (dispatch: Function) =>
    dispatch({
      types: [
        DELETE_COLLEGES_IAM_THINKING,
        DELETE_COLLEGES_IAM_THINKING_SUCCESS,
        DELETE_COLLEGES_IAM_THINKING_FAIL,
      ],
      promise: (client: Object) =>
        client
          .delete(`${api.colleges}/colleges-im-thinking-about`, {
            data: {
              [idKey]: colleges,
            },
          })
          .then((data) => {
            // Debugging lines
            console.debug('POST colleges-im-thinking-about response data: ', data);
            // Check if dispatchMfeEvent is defined
            if (typeof dispatchMfeEvent !== 'function') {
              console.debug('dispatchMfeEvent is not defined correctly.');
            } else {
              data.forEach((item) => {
                // Add a null/undefined check for item and item.college
                if (item && item.college) {
                  dispatchMfeEvent(
                    'naviance_student',
                    NAV_STUDENT_EVENT_COLLEGE_UNFAVORITED,
                    item.college
                  );
                } else {
                  console.debug('Item or item.college is undefined:', item);
                }
              });
            }

            // Trigger refetch of the full list of CITA colleges so that the list is up to date
            dispatch(fetchAllConsideringInIsolation());
          })
          .catch((error) => {
            console.error('POST colleges-im-thinking-about call failed: ', error);
          }),
    });
}

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case NEXT_PAGE:
    case GET_SINGLE:
    case GET_COLLEGES_IAM_THINKING:
    case GET_COLLEGE_DEADLINE_TYPES: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COLLEGES_IAM_THINKING_SUCCESS: {
      return {
        ...state,
        results: collectIds(action.result),
        entities: normalizeItems(action.result),
        totalItems: action.result.totalItems,
        loading: false,
        page: 1,
      };
    }
    case GET_COLLEGE_DEADLINE_TYPES_SUCCESS: {
      return {
        ...state,
        collegeDeadlineTypes: [...state.collegeDeadlineTypes, ...action.result],
      };
    }
    case CLEAR_DEADLINE_TYPES: {
      return {
        ...state,
        collegeDeadlineTypes: [],
      };
    }
    case GET_COLLEGES_IAM_THINKING_IN_ISOLATION: {
      return {
        ...state,
        loadingAll: true,
      };
    }
    case GET_COLLEGES_IAM_THINKING_IN_ISOLATION_SUCCESS: {
      return {
        ...state,
        allResults: collectIds(action.result),
        allEntities: normalizeItems(action.result),
        loadingAll: false,
      };
    }
    case NEXT_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        page: expandObject(action, 'result.page') || 1,
        results: [...(state.results.concat(collectIds(action.result)) || [])],
        totalItems: action.result.totalItems,
        entities: { ...state.entities, ...normalizeItems(action.result) },
      };
    case GET_SINGLE_SUCCESS: {
      const data = expandObject(action, 'result.data.0');
      const { id } = data;

      return {
        ...state,
        entities: { ...state.entities, [id]: data },
        loading: false,
      };
    }
    case NEXT_PAGE_FAILURE:
    case GET_SINGLE_FAIL:
    case GET_COLLEGES_IAM_THINKING_FAIL:
    case GET_COLLEGE_DEADLINE_TYPES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_COLLEGES_IAM_THINKING_IN_ISOLATION_FAIL: {
      return {
        ...state,
        loadingAll: false,
      };
    }
    case SELECT_COLLEGE:
      return {
        ...state,
        pickedCollegeIds: [...state.pickedCollegeIds, action.college.id],
        collegeIdSelections: [...state.collegeIdSelections, action.college.collegeId],
      };
    case UNSELECT_COLLEGE: {
      const cIds = state.pickedCollegeIds;
      const previousCollegeIdSelections = state.collegeIdSelections;
      const index = cIds.indexOf(action.college.id);
      return {
        ...state,
        pickedCollegeIds: [...cIds.slice(0, index), ...cIds.slice(index + 1)],
        collegeIdSelections: [
          ...previousCollegeIdSelections.slice(0, index),
          ...previousCollegeIdSelections.slice(index + 1),
        ],
      };
    }
    case CLEAR_COLLEGES:
      return {
        ...state,
        pickedCollegeIds: [],
        collegeIdSelections: [],
      };
    case CLEAR_FAVORITE_LIST:
      lastPage = -1;
      return {
        ...state,
        results: [],
        entities: [],
        page: -1,
        totalItems: 0,
      };
    case SAVE_COLLEGES_IAM_THINKING_SUCCESS:
      return {
        ...state,
        pickedCollegeIds: [],
        collegeIdSelections: [],
      };
    case SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING:
    case DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING:
      return {
        ...state,
        loading: true,
      };
    case SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_SUCCESS:
    case SAVE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_FAIL:
    case DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_SUCCESS:
    case DELETE_COLLEGES_IAM_THINKING_WITHOUT_CLEARING_FAIL:
      return {
        ...state,
      };
    case DELETE_COLLEGES_IAM_THINKING: {
      return {
        ...state,
        pickedCollegeIds: [],
        collegeIdSelections: [],
      };
    }

    case CLEAN_PICKED_COLLEGES:
    case DELETE_COLLEGES_IAM_THINKING_SUCCESS:
    case SAVE_COLLEGES_IAM_THINKING_FAIL: {
      lastPage = -1;
      return {
        ...state,
        pickedCollegeIds: [],
        collegeIdSelections: [],
      };
    }
    case SET_PICKED_COLLEGES:
      return {
        ...state,
        pickedCollegeIds: [...state.pickedCollegeIds, ...action.collegeIds],
      };
    case SELECT_INTEREST:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: {
            ...state.entities[action.payload.id],
            selectedInterest: action.payload.interestId,
          },
        },
      };
    case ADD_TO_APPLICATION_LIST:
      return {
        ...state,
        application: {
          results: [...action.applicationIds],
          entities: {
            ...filterItems(action.applicationIds, action.entities || state.entities),
          },
        },
        pickedCollegeIds: [...action.applicationIds],
        loading: false,
      };
    case REMOVE_COLLEGE_FROM_PICKED:
      return {
        ...state,
        pickedCollegeIds: state.pickedCollegeIds.filter((ci) => ci !== action.collegeId),
        collegeIdSelections: state.collegeIdSelections.filter((ci) => ci !== action.collegeId),
      };
    case REMOVE_FROM_APPLICATION_LIST:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_APPLICATION_LIST: {
      const { application } = state;
      const { id, field, value, deadlineId } = action;
      const collegeDeadlineId = 'collegeDeadlineId';
      application.entities[id][field] = value;
      application.entities[id][collegeDeadlineId] = deadlineId;

      return {
        ...state,
        application,
        loading: false,
      };
    }
    case CHANGE_IS_LOADING_LEGACY_MATCHES:
      return {
        ...state,
        wereLegacyMatchesLoaded: action.wereLegacyMatchesLoaded,
      };
    default:
      return state;
  }
}

export const fetchAllConsidering = () => (dispatch: Function) =>
  dispatch({
    types: [
      GET_COLLEGES_IAM_THINKING,
      GET_COLLEGES_IAM_THINKING_SUCCESS,
      GET_COLLEGES_IAM_THINKING_FAIL,
    ],
    promise: (client: Object) =>
      client.get(`${api.colleges}/colleges-im-thinking-about?limit=${FETCH_MAX_RESULTS_LARGE}`),
  });

export const fetchDeadlineTypes = (collegeId: string) => (dispatch: Function) =>
  dispatch({
    types: [
      GET_COLLEGE_DEADLINE_TYPES,
      GET_COLLEGE_DEADLINE_TYPES_SUCCESS,
      GET_COLLEGE_DEADLINE_TYPES_FAIL,
    ],
    promise: (client: Object) => client.get(`${api.colleges}/deadlines?cid=${collegeId}`),
  });

export const clearDeadlineTypes = () => (dispatch: Function) =>
  dispatch({ type: CLEAR_DEADLINE_TYPES });

export function fetchAllConsideringInIsolation() {
  return (dispatch: Function) =>
    dispatch({
      types: [
        GET_COLLEGES_IAM_THINKING_IN_ISOLATION,
        GET_COLLEGES_IAM_THINKING_IN_ISOLATION_SUCCESS,
        GET_COLLEGES_IAM_THINKING_IN_ISOLATION_FAIL,
      ],
      promise: (client: Object) =>
        client.get(`${api.colleges}/colleges-im-thinking-about?limit=${FETCH_MAX_RESULTS_LARGE}`),
    });
}

// how many pages per request?
const limit = 10;
export const searchConsidering = (lastIndex: number = 0) => (
  dispatch: Function,
  getState: Function
) => {
  const totalResults = getState().colleges.considering.results.length;
  const { totalItems } = getState().colleges.considering;

  // if last rendered index has passed half of a page, then increment page by one (round up)

  const { requestUrl, types, nextPage, proceed } = paginatedSearch({
    baseUrl: `${api.colleges}/colleges-im-thinking-about`,
    lastIndex,
    lastPage,
    limit,
    totalResults,
    totalItems,
    firstPageTypes: [
      GET_COLLEGES_IAM_THINKING,
      GET_COLLEGES_IAM_THINKING_SUCCESS,
      GET_COLLEGES_IAM_THINKING_FAIL,
    ],
    nextPageTypes: [NEXT_PAGE, NEXT_PAGE_SUCCESS, NEXT_PAGE_FAILURE],
  });
  if (!proceed || (totalItems !== 0 && nextPage > Math.ceil(totalItems / limit))) {
    // $FlowFixMe
    return Promise.resolve(true);
  }
  lastPage = nextPage || -1;

  return dispatch({
    types,
    promise: (client: Object) => client.get(requestUrl),
  });
};

export function saveCollegesIamThinking(colleges: Array<number>) {
  return saveCollegesIamThinkingInternal(colleges, 'collegeIds');
}

export function saveCollegesIamThinkingByHobsonsIds(colleges: Array<number>) {
  return saveCollegesIamThinkingInternal(colleges, 'hobsonsIds');
}

export function deleteCollegesIamThinking(applicationIds: number[]) {
  return deleteCollegesIamThinkingInternal(applicationIds, 'prospectiveApplicationIds');
}

export const deleteCollegesIamThinkingWithNotify = (applicationIds: number[]) => (
  dispatch: Function
) =>
  dispatch(deleteCollegesIamThinking(applicationIds)).then(() => {
    dispatch(
      notify({
        type: 'success',
        title: 'Confirmation',
        content: `${applicationIds.length} college(s) removed successfully`,
      })
    );
  });

export function deleteCollegesIamThinkingByHobsonsIds(hobsonsIds: Array<number>) {
  return deleteCollegesIamThinkingInternal(hobsonsIds, 'hobsonsIds');
}

export function pickCollege(college: CollegeType, type: string) {
  return (dispatch: Function) => {
    if (college && college.id) {
      dispatch({
        type: type === 'add' ? SELECT_COLLEGE : UNSELECT_COLLEGE,
        college,
      });
    }
  };
}

export function selectInterest(id: number, interestId: number) {
  return {
    type: SELECT_INTEREST,
    payload: {
      id,
      interestId,
    },
  };
}

export function updateInterests(applicationIds: number[]) {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [UPDATE_INTERESTS, UPDATE_INTERESTS_SUCCESS, UPDATE_INTERESTS_FAIL],
      promise: (client: Object) => {
        const entities = getConsideringEntities(getState());
        const promises = Object.keys(entities).reduce((acc, key) => {
          const item = entities[key];

          if (applicationIds.includes(item.id) && isFinite(item.selectedInterest)) {
            return [
              ...acc,
              client.put(
                `${api.colleges}/colleges-im-thinking-about/${item.prospectiveApplicationId}`,
                {
                  data: {
                    interest: item.selectedInterest,
                  },
                }
              ),
            ];
          }

          return acc;
        }, []);

        return Promise.all(promises);
      },
    });
}

export const updateInterestsWithNotify = (applicationIds: number[]) => (dispatch: Function) =>
  dispatch(updateInterests(applicationIds)).then(() => {
    dispatch(
      notify({
        type: 'success',
        title: 'Confirmation',
        content: `${applicationIds.length} college(s) updated successfully`,
      })
    );
  });

export function addToApplicationList(applicationIds: Array<number>, entities?: Object) {
  return {
    type: ADD_TO_APPLICATION_LIST,
    applicationIds,
    entities,
  };
}

export function updateApplicationList(
  field: string,
  id: string,
  value: boolean,
  deadlineId?: string
) {
  return {
    type: UPDATE_APPLICATION_LIST,
    field,
    id,
    value,
    deadlineId,
  };
}
export function cleanPickedColleges() {
  return {
    type: CLEAN_PICKED_COLLEGES,
  };
}

export function clearFavoriteList() {
  return {
    type: CLEAR_FAVORITE_LIST,
  };
}

export function setPickedColleges(collegeIds: string[] = []) {
  return (dispatch: Function) => {
    const queryString = `filter=${encodeURIComponent(
      JSON.stringify({ 'college.id': `IN [${collegeIds.join(',')}]` })
    )}`;
    return dispatch({
      types: [SET_PICKED_COLLEGES, SET_PICKED_COLLEGES_SUCCESS, SET_PICKED_COLLEGES_FAIL],
      promise: (client: Object) => client.get(`${api.colleges}/search?${queryString}`),
      collegeIds,
    });
  };
}

export function fetchCollege(collegeId: string = '') {
  return (dispatch: Function, getState: Function) => {
    const queryString = `filter=${encodeURIComponent(JSON.stringify({ 'college.id': collegeId }))}`;
    const existing = expandObject(getState(), `colleges.considering.entities.${collegeId}`);
    // $FlowExpectedError
    if (existing) return Promise.resolve();

    return dispatch({
      types: [GET_SINGLE, GET_SINGLE_SUCCESS, GET_SINGLE_FAIL],
      promise: (client: Object) => client.get(`${api.colleges}/search?${queryString}`),
      collegeId,
    });
  };
}

export const changeWereLegacyMatchesLoaded = (wereLegacyMatchesLoaded: boolean) => ({
  type: CHANGE_IS_LOADING_LEGACY_MATCHES,
  wereLegacyMatchesLoaded,
});
